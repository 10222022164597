@font-face {
  font-family: "Myriad Pro Bold";
  font-weight: normal;
  font-style: normal;
  src: url("./fonts/Myriad\ Pro\ Bold.ttf");
}

@font-face {
  font-family: "Myriad Pro Semibold";
  font-weight: normal;
  font-style: normal;
  src: url("./fonts/Myriad\ Pro\ Semibold.ttf");
}

@font-face {
  font-family: "Myriad Pro Regular";
  font-weight: normal;
  font-style: normal;
  src: url("./fonts/Myriad\ Pro\ Regular.ttf");
}

body {
  margin: 0;
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
